import {
    Stack,
    Box,
    Typography,
    Autocomplete,
    TextField,
    Button,
} from "@mui/material";
import { constants } from "../../../../config/constants";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const Language = () => {
    const { i18n, t } = useTranslation();
    const [language, setLanguage] = useState(
        localStorage.getItem(constants.storage.language) || "en"
    );
    const [pendingLanguage, setPendingLanguage] = useState(language);

    const confirmLanguageChange = () => {
        setLanguage(pendingLanguage);
        localStorage.setItem(constants.storage.language, pendingLanguage);
        i18n.changeLanguage(pendingLanguage);
        window.location.reload();
    };

    const languageOptions = [
        { label: "English", value: "en" },
        { label: "Arabic", value: "ar" },
    ];

    return (
        <Stack spacing={8} minWidth="100%">
            <Stack
                width="100%"
                minHeight="100px"
                backgroundColor="white"
                borderRadius={5}
                padding={5}
                sx={{
                    boxShadow: "0 1px 3px hsla(220,6%,43%,0.2)",
                }}
                flex={1}
                mt={2}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Stack spacing={3}>
                        <Box width={250}>
                            <Typography
                                color="#000000"
                                fontSize={16}
                                sx={{ textTransform: "capitalize" }}
                            >
                                {t("navigation.language")}
                            </Typography>
                        </Box>

                        <Box>
                            <Typography
                                color="#4D5568"
                                fontSize={14}
                                sx={{ textTransform: "capitalize" }}
                            >
                                {t("navigation.language_instructions")}
                            </Typography>
                        </Box>

                        <Box width={400}>
                            <Autocomplete
                                size="small"
                                value={
                                    languageOptions.find(
                                        (option) =>
                                            option.value === pendingLanguage
                                    ) || null
                                }
                                onChange={(e, value) =>
                                    setPendingLanguage(value?.value || language)
                                }
                                disablePortal
                                options={languageOptions}
                                sx={{ width: 400 }}
                                disableClearable
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        sx={{ height: 32 }}
                                        // label={t("navigation.language")}
                                        InputLabelProps={{
                                            style: {
                                                color: "#6B7280",
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Box>

                        <Box sx={{ paddingTop: 5 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={confirmLanguageChange}
                                sx={{
                                    height: 40,
                                    width: 150,
                                    textTransform: "capitalize",
                                    backgroundColor: "#5BCFDE",
                                    "&:hover": { backgroundColor: "#48B7C5" }, // Slightly darker on hover
                                }}
                            >
                                {t("navigation.confirm")}
                            </Button>
                        </Box>
                    </Stack>
                </Box>
            </Stack>
        </Stack>
    );
};

export { Language };
