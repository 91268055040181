import SpeedIcon from "@mui/icons-material/Speed";
import CampaignIcon from "@mui/icons-material/Campaign";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import TuneIcon from "@mui/icons-material/Tune";
import { getUserStorage } from "../../utils";

const user = getUserStorage();
const isBasicPlan =
    user?.merchant?.subscription === 2 || user?.merchant?.subscription === 4;
const isOwner = user?.role === 1;
const isGroupOwner = user?.role === 9;

// Permissions
const hasLikeForLikeAccess = true;
const hasBasketAccess = true;
const hasSalesAccess = true;
const hasSalesInsightsAccess = true;
const hasSalesPerformanceAccess = true;
const hasSalesAggregatorsAccess = true;
const hasCustomersAccess = true;
const hasCustomersDataAccess = true;
const hasCustomersInsightsAccess = true;
const hasMenuAccess = true;
const hasMenuEngineeringAccess = true;

export const sidebarData = [
    {
        title: "navigation.performance",
        icon: (
            <SpeedIcon
                style={{ color: "#00D1B2", marginRight: 8, marginLeft: 8 }}
            />
        ),
        items: [
            {
                label: "navigation.overview",
                path: "/overview",
                iconPath: "/ic_overview_selected.svg",
            },
            hasSalesAccess && {
                label: "navigation.salesInsights",
                path: hasSalesInsightsAccess ? "/sales/insights" : null,
                iconPath: "/ic_sales_selected.svg",
            },
            hasSalesPerformanceAccess && {
                label: "navigation.salesPerformance",
                path: "/sales/performance",
                iconPath: "/sales performance.svg",
            },
            hasSalesAggregatorsAccess && {
                label: "navigation.deliverySales",
                path: "/sales/aggregators",
                iconPath: "/sales_aggregators.svg",
            },
            hasBasketAccess && {
                label: "navigation.basketAnalysis",
                path: "/basket",
                iconPath: "/ic_bought_selected.svg",
            },
            hasLikeForLikeAccess && {
                label: "navigation.likeForLike",
                path: "/likeForLike",
                iconPath: "/ic_like for like_selected.svg",
            },
            hasMenuEngineeringAccess && {
                label: "navigation.menuEngineering",
                path: "/menu",
                iconPath: "/menu_products.svg",
            },
        ].filter(Boolean),
    },
    {
        title: "navigation.marketing",
        icon: (
            <CampaignIcon
                style={{ color: "#00D1B2", marginRight: 8, marginLeft: 8 }}
            />
        ),
        items: [
            {
                label: "navigation.key_events",
                path: "/pixel",
                iconPath: "/forcast_history.svg",
            },
            {
                label: "navigation.offer_desinger",
                path: "/offer-designer",
                isDisabled: true,
                iconPath: "/forcast_overview.svg",
            },
            hasCustomersDataAccess && {
                label: "navigation.customers",
                path: "/customers/data",
                iconPath: "/customers_data.svg",
            },
            hasCustomersInsightsAccess && {
                label: "navigation.customers_insights",
                path: "/customers/insights",
                iconPath: "/customer_insights.svg",
            },
        ].filter(Boolean),
    },
    {
        title: "navigation.ai",
        icon: (
            <PersonIcon
                style={{ color: "#00D1B2", marginRight: 8, marginLeft: 8 }}
            />
        ),
        items: [
            {
                label: "navigation.salesForecast",
                path: "/forcast/overview",
                isDisabled: true,
                iconPath: "/forcast_overview.svg",
            },
            {
                label: "navigation.aiAnalyst",
                path: "/assistant",
                iconPath: "/assistant.svg",
            },
        ],
    },
    {
        title: "navigation.settingsPreferences",
        icon: (
            <TuneIcon
                style={{ color: "#00D1B2", marginRight: 8, marginLeft: 8 }}
            />
        ),
        items: [
            {
                label: "navigation.settings",
                path: "/settings",
                icon: <SettingsIcon style={{ color: "#5BCFDE" }} />,
            },
        ],
    },
];
