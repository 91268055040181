import { Box, Button, Stack } from "@mui/material";
import { PageHeader } from "../../../common/components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MyTeam } from "./MyTeam";
import { MyBranches } from "./MyBranches";
import { Subscription } from "./Subscription";
import { Language } from "./language/Language";
import { getUserStorage } from "../../../common/utils";
import { Owners } from "./Owners";

export const Settings = () => {
    const { t } = useTranslation();
    const user = getUserStorage();

    const isGroupOwner = user.role === 9;
    const isOwner = user.role === 1;

    const [selectedPage, setSelectedPage] = useState(
        isOwner ? "branches" : "teams"
    );

    return (
        <Stack padding={8} paddingTop={0} spacing={0} minWidth="100%">
            <Stack>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    padding={0}
                    height={100}
                >
                    <Box display="flex" alignItems="baseline" gap={4}>
                        {isOwner && (
                            <Button
                                sx={{
                                    color:
                                        selectedPage === "branches" &&
                                        "#00C08B",
                                    backgroundColor:
                                        selectedPage === "branches" &&
                                        "#EFFEF4",
                                    "&:hover": {
                                        backgroundColor: "#EFFEF4",
                                    },
                                    textTransform: "capitalize",
                                    borderRadius: "8px",
                                }}
                                onClick={() => setSelectedPage("branches")}
                            >
                                {t("settings.my_branches")}
                            </Button>
                        )}

                        <Button
                            sx={{
                                color: selectedPage === "teams" && "#00C08B",
                                backgroundColor:
                                    selectedPage === "teams" && "#EFFEF4",
                                "&:hover": {
                                    backgroundColor: "#EFFEF4",
                                    color: "#00C08B",
                                },
                                textTransform: "capitalize",
                                // backgroundColor: "#5BCFDE",
                                borderRadius: "8px",
                            }}
                            onClick={() => setSelectedPage("teams")}
                        >
                            {t("settings.my_team")}
                        </Button>

                        {isOwner && (
                            <Button
                                sx={{
                                    color:
                                        selectedPage === "subscriptions" &&
                                        "#00C08B",
                                    backgroundColor:
                                        selectedPage === "subscriptions" &&
                                        "#EFFEF4",
                                    "&:hover": {
                                        backgroundColor: "#EFFEF4",
                                        color: "#00C08B",
                                    },
                                    textTransform: "capitalize",
                                    // backgroundColor: "#5BCFDE",
                                    borderRadius: "8px",
                                }}
                                onClick={() => setSelectedPage("subscriptions")}
                            >
                                {t("settings.subscriptions")}
                            </Button>
                        )}

                        {
                            <Button
                                sx={{
                                    color:
                                        selectedPage === "display" && "#00C08B",
                                    backgroundColor:
                                        selectedPage === "display" && "#EFFEF4",
                                    "&:hover": {
                                        backgroundColor: "#EFFEF4",
                                        color: "#00C08B",
                                    },
                                    textTransform: "capitalize",
                                    // backgroundColor: "#5BCFDE",
                                    borderRadius: "8px",
                                }}
                                onClick={() => setSelectedPage("display")}
                            >
                                {t("navigation.display")}
                            </Button>
                        }
                    </Box>
                </Box>

                {selectedPage === "branches" && <MyBranches />}

                {selectedPage === "teams" &&
                    (isGroupOwner ? <Owners /> : <MyTeam />)}

                {selectedPage === "subscriptions" && <Subscription />}
                {selectedPage === "display" && <Language />}
            </Stack>
        </Stack>
    );
};
