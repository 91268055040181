import { Box, Button, Stack } from "@mui/material";
import { PageHeader } from "../../../common/components";
import { useState } from "react";
import { LikeForLikeBranches } from "./Branches/Branches";
import { LikeForLikeCategories } from "./Categories/Categories";
import { LikeForLikeProducts } from "./Products/Products";
import { useTranslation } from "react-i18next";

export const LikeForLike = () => {
    const { t } = useTranslation();
    const [selectedPage, setSelectedPage] = useState("branches");

    return (
        <Stack padding={8} paddingTop={0} spacing={0} minWidth="100%">
            <Stack>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    padding={0}
                    height={100}
                >
                    <Box display="flex" alignItems="baseline" gap={4}>
                        <Button
                            sx={{
                                color: selectedPage === "branches" && "#00C08B",
                                backgroundColor:
                                    selectedPage === "branches" && "#EFFEF4",
                                "&:hover": {
                                    backgroundColor: "#EFFEF4",
                                },
                                textTransform: "capitalize",
                                borderRadius: "8px",
                            }}
                            onClick={() => setSelectedPage("branches")}
                        >
                            {t("likeForLike.branch_like")}
                        </Button>

                        <Button
                            sx={{
                                color:
                                    selectedPage === "categroies" && "#00C08B",
                                backgroundColor:
                                    selectedPage === "categroies" && "#EFFEF4",
                                "&:hover": {
                                    backgroundColor: "#EFFEF4",
                                    color: "#00C08B",
                                },
                                textTransform: "capitalize",
                                // backgroundColor: "#5BCFDE",
                                borderRadius: "8px",
                            }}
                            onClick={() => setSelectedPage("categroies")}
                        >
                            {t("likeForLike.category_like")}
                        </Button>

                        <Button
                            sx={{
                                color: selectedPage === "products" && "#00C08B",
                                backgroundColor:
                                    selectedPage === "products" && "#EFFEF4",
                                "&:hover": {
                                    backgroundColor: "#EFFEF4",
                                    color: "#00C08B",
                                },
                                textTransform: "capitalize",
                                // backgroundColor: "#5BCFDE",
                                borderRadius: "8px",
                            }}
                            onClick={() => setSelectedPage("products")}
                        >
                            {t("likeForLike.product_like")}
                        </Button>
                    </Box>
                </Box>

                {selectedPage === "branches" && <LikeForLikeBranches />}

                {selectedPage === "categroies" && <LikeForLikeCategories />}

                {selectedPage === "products" && <LikeForLikeProducts />}
            </Stack>
        </Stack>
    );
};
