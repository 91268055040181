import { Box, Button, Stack } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BasketProduct } from "./Product/BasketProduct";
import { BasketFreq } from "./Freq";

export const BasketPage = () => {
    const { t } = useTranslation();
    const [selectedPage, setSelectedPage] = useState("freq");

    return (
        <Stack padding={8} paddingTop={0} spacing={0} minWidth="100%">
            <Stack>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    padding={0}
                    height={100}
                >
                    <Box display="flex" alignItems="baseline" gap={4}>
                        <Button
                            sx={{
                                color: selectedPage === "freq" && "#00C08B",
                                backgroundColor:
                                    selectedPage === "freq" && "#EFFEF4",
                                "&:hover": {
                                    backgroundColor: "#EFFEF4",
                                },
                                textTransform: "capitalize",
                                borderRadius: "8px",
                            }}
                            onClick={() => setSelectedPage("freq")}
                        >
                            {t("basket.freq")}
                        </Button>

                        <Button
                            sx={{
                                color: selectedPage === "basket" && "#00C08B",
                                backgroundColor:
                                    selectedPage === "basket" && "#EFFEF4",
                                "&:hover": {
                                    backgroundColor: "#EFFEF4",
                                    color: "#00C08B",
                                },
                                textTransform: "capitalize",
                                // backgroundColor: "#5BCFDE",
                                borderRadius: "8px",
                            }}
                            onClick={() => setSelectedPage("basket")}
                        >
                            {t("basket.basket")}
                        </Button>
                    </Box>
                </Box>

                {selectedPage === "basket" && <BasketProduct />}

                {selectedPage === "freq" && <BasketFreq />}
            </Stack>
        </Stack>
    );
};
