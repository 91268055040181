import {
    FormControl,
    InputBase,
    MenuItem,
    Select,
    Typography,
    Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const CustomSelectCSS = styled(InputBase)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
        position: "relative",
        // backgroundColor: "white",
        border: "1px solid #ced4da",
        padding: "5px 16px 6px 12px",
        fontSize: 14,
        borderRadius: "16px",
        borderRight: 0,
        // "&:focus": {
        //     borderRadius: "16px",
        // },
    },
}));

const AddNew = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <FormControl
            sx={{
                width: 150,
                backgroundColor: "white",
                height: 36,
                borderRadius: "16px",
                position: "relative",
            }}
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "5px",
                    left: "14px",
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                }}
            >
                <Typography>{t("common.add")}</Typography>
            </Box>

            <Select
                value="Export"
                label="Export"
                placeholder="Export"
                displayEmpty
                input={<CustomSelectCSS />}
                IconComponent={ExpandMoreIcon}
            >
                <MenuItem onClick={() => navigate("/settings/owners/new")}>
                    {t("settings.add_owner")}
                </MenuItem>
                <MenuItem onClick={() => navigate("/settings/owners/newGroup")}>
                    {t("settings.add_group_manager")}
                </MenuItem>
            </Select>
        </FormControl>
    );
};

export { AddNew };
