import { useEffect, useMemo, useState } from "react";
import {
    Box,
    Divider,
    Grid,
    MenuItem,
    Select,
    Stack,
    Switch,
    Typography,
} from "@mui/material";
import {
    ButtonSelect,
    CustomSelectOutline,
    DoughnutChart,
    GridCard,
    GridCardFlatLabels,
    RangePicker,
} from "../../../../../common/components";
import { ComposedTrendingHours } from "../../components/ComposedTrendingHours";
import { TopProducts } from "../../components/TopProducts";
import { services } from "../services";
import { useMutation, useQuery } from "@tanstack/react-query";
import { format, isToday, subMonths, subYears } from "date-fns";
import { useTranslation } from "react-i18next";

import { constants } from "../../../../../config/constants";
import { numberWithCommas } from "../../../../../common/utils/numberWithCommas";
import { getUserStorage } from "../../../../../common/utils";
import { toast } from "react-toastify";
// import { ExportButton } from "../../../../../common/components/Export/Export";
import { OrdersChart } from "../charts/OrdersChart";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { differenceInDays, subDays } from "date-fns";
import { AuthServices } from "../../../../../Auth/services";

function getPreviousPeriod(startDate, endDate) {
    const daysDifference = differenceInDays(endDate, startDate);

    // General case for custom period
    const previousStartDate = subDays(startDate, daysDifference);
    const previousEndDate = subDays(endDate, daysDifference + 1);
    return { previousStartDate, previousEndDate };
}

const emptyDataPie = [{ name: "Not Enough Data", value: 100, fill: "#E3E3E3" }];

const Operations = () => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const { data: firstDateData } = useQuery({
        queryKey: ["firstSalesDate"],
        queryFn: AuthServices.getMerchantFirstDate,
        refetchOnWindowFocus: false,
    });

    const [period, setPeriod] = useState({
        startDate: format(new Date(), "yyyy-MM-dd"),
        endDate: format(new Date(), "yyyy-MM-dd"),
    });

    const user = getUserStorage();
    const isOwner = user.role === 1;
    const userBranches =
        isOwner && !user.manager_view ? user?.merchant?.branches : user?.branch;
    const allBranches = userBranches.map((branch) => ({
        id: branch.branch_id,
        label: branch.name,
    }));
    const isGroupOwner = user.role === 9;

    const userGroups = user?.group;
    const allGroups =
        userGroups?.map((group) => ({
            id: group.merchant_id,
            label: group.name,
        })) || [];
    const allGroupsOption = { id: "all", label: t("common.all_groups") };
    const [selectedGroup, setSelectedGroup] = useState(allGroupsOption.id);

    const allChannelsOption = { id: "all", label: t("common.all_channels") };
    const isManagerView = user.manager_view;
    const allBranchesOption = { id: "all", label: t("common.all_branches") };
    const [selectedBranch] = useState(
        isManagerView ? allBranches[0].id : allBranchesOption.id
    );
    const [selectedChannel] = useState(allChannelsOption.id);

    const [topProducts, setTopProducts] = useState([]);
    const [trendingHours, setTrendingHours] = useState(null);
    const [channelsSales, setChannelSales] = useState([]);
    const [orderCanclledTotals, setOrderCanclledTotals] = useState(null);
    const [totalLostData, setTotalLostData] = useState(null);

    const isSingleChannelSelected = selectedChannel !== allChannelsOption.id;

    const isTodayFilterApplied = useMemo(
        () =>
            isToday(new Date(period.startDate)) &&
            isToday(new Date(period.endDate)),
        [period]
    );

    const [compareWithPrev, setCompareWithPrev] =
        useState(isTodayFilterApplied);

    const { mutate: getSalesAggregatorsOperations, isLoading } = useMutation({
        mutationFn: services.getSalesAggregatorsOperations,
        onSuccess: (res) => {
            if (res.data.response && !res.data.error) {
                const response = res.data.response;

                const hours = response.hourly_trending;
                const refinedHours = refineSalesTrendingHours(hours);
                const trending_hours = refinedHours.trendingHours;

                const channelsSalesTotal = getTotalCount(
                    refinedHours.channelsSales
                );

                const channel_sales = !!refinedHours.channelsSales.length
                    ? refinedHours.channelsSales.map((channel, index) => ({
                          name: channel.name,
                          value:
                              Math.ceil(
                                  (
                                      channel.total_sales / channelsSalesTotal
                                  ).toFixed(2) * 1000
                              ) / 10,
                          sales: numberWithCommas(channel.total_sales),
                          fill: constants.pieColors[index],
                          // innerRadius: (index + 1) * 10,
                          outerRadius: 115 + (index + 1) * 10,
                      }))
                    : emptyDataPie;

                const top_products = response.sales_top_bottom;

                setTopProducts(top_products);
                setChannelSales(channel_sales);
                setTrendingHours(trending_hours);

                setOrderCanclledTotals(response.order_canclled_totals[0]);
                setTotalLostData(
                    response.lost_sales_interval.map((i) => ({
                        date: i.grouped_date,
                        value: i.total_canceled_orders,
                    }))
                );
            }

            if (res.data.error) {
                toast.error(res.data.message, {
                    hideProgressBar: true,
                });
            }
        },
        onError: (err) => console.log(err),
    });

    function getTotalCount(arr, key = "total_sales") {
        let count = 0;
        arr.forEach((i) => (count = count + +i[key]));

        return count;
    }

    const refineSalesTrendingHours = (arr) => {
        const allHours = {};
        const allChannels = {};
        const channelsSales = [];
        const trendingHoursArr = [];

        for (let i = 0; i < 24; i++) {
            allHours[i] = {};
        }

        for (let hour of arr) {
            const hourNumber = +hour.hour_number;
            const hourSales = +hour.total_sales;
            const hourChannelName = hour.channel_name;

            allHours[hourNumber] = {
                ...allHours[hourNumber],
                [`${hour.channel_name}`]: hourSales,
                name: constants.hours[`${hour.hour_number}`],
                total_sales: (allHours[hourNumber] || 0) + hourSales,
            };

            if (allChannels[hourChannelName])
                allChannels[hourChannelName] =
                    allChannels[hourChannelName] + hourSales;
            else allChannels[hourChannelName] = hourSales;
        }

        Object.keys(allChannels).forEach((channel) => {
            if (allChannels[`${channel}`])
                channelsSales.push({
                    name: channel,
                    total_sales: +allChannels[`${channel}`].toFixed(2),
                });
        });

        Object.values(allHours).forEach((hour) => {
            trendingHoursArr.push(hour);
        });

        return { trendingHours: trendingHoursArr, channelsSales };
    };

    useEffect(() => {
        if (period.startDate) {
            const prevDate = getPreviousPeriod(
                new Date(period.startDate),
                new Date(period.endDate)
            );

            getSalesAggregatorsOperations({
                from_date: period.startDate,
                till_date: period.endDate,
                ...(compareWithPrev
                    ? {
                          prev_from_date: format(
                              new Date(prevDate.previousStartDate),
                              "yyyy-MM-dd"
                          ),
                          prev_till_date: format(
                              new Date(prevDate.previousEndDate),
                              "yyyy-MM-dd"
                          ),
                      }
                    : {}),
                channel_id: "all",
                branch_id: "all",
            });
        }
    }, [compareWithPrev, period.startDate, period.endDate]);

    const handleDatesChange = ({ startDate, endDate }) => {
        if (startDate !== "1970-1-1") {
            setPeriod({ startDate, endDate });
        }
    };

    const handleTodayDatesChange = (value) => {
        let prevStartDate = period.startDate;
        let prevEndDate = period.endDate;

        if (value === "yesterday") {
            prevStartDate = subDays(new Date(), 1);
            prevEndDate = subDays(new Date(), 1);
        }
        if (value === "same_day_prev_week") {
            prevStartDate = subDays(new Date(), 7);
            prevEndDate = subDays(new Date(), 7);
        }
        if (value === "same_day_prev_month") {
            prevStartDate = subMonths(new Date(), 1);
            prevEndDate = subMonths(new Date(), 1);
        }
        if (value === "same_day_prev_3_month") {
            prevStartDate = subMonths(new Date(), 3);
            prevEndDate = subMonths(new Date(), 3);
        }
        if (value === "same_day_prev_year") {
            prevStartDate = subYears(new Date(), 1);
            prevEndDate = subYears(new Date(), 1);
        }

        if (period.startDate !== "1970-1-1") {
            getSalesAggregatorsOperations({
                from_date: period.startDate,
                till_date: period.endDate,

                prev_from_date: format(new Date(prevStartDate), "yyyy-MM-dd"),
                prev_till_date: format(new Date(prevEndDate), "yyyy-MM-dd"),
                branch_id: selectedBranch,
                channel_id: selectedChannel,
            });
        }
    };

    const handleGroupChange = (e) => {
        const newSelectedGroupValue = e.target.value;
        setSelectedGroup(newSelectedGroupValue);

        getSalesAggregatorsOperations({
            from_date: period.startDate,
            till_date: period.endDate,
            ...(compareWithPrev
                ? {
                      prev_from_date: period.startDate,
                      prev_till_date: period.endDate,
                  }
                : {}),
            merchant_filter: newSelectedGroupValue,
        });
    };

    useEffect(() => {
        window.pendo.pageLoad({
            name: "Sales Aggregators Page",
        });
    }, []);

    const isAllTimeFilterApplied = useMemo(() => {
        if (!firstDateData?.data?.response?.first_date) return false;
        if (
            format(new Date(period.startDate), "yyyy-MM-dd") ===
                format(
                    new Date(firstDateData?.data?.response?.first_date),
                    "yyyy-MM-dd"
                ) &&
            isToday(new Date(period.endDate))
        ) {
            return true;
        }
    }, [firstDateData, period]);

    useEffect(() => {
        if (compareWithPrev && isAllTimeFilterApplied)
            setCompareWithPrev(false);
        if (!compareWithPrev && isTodayFilterApplied) setCompareWithPrev(true);
    }, [isTodayFilterApplied, compareWithPrev, isAllTimeFilterApplied]);

    return (
        <>
            <Box display="flex" justifyContent="space-between" width="100%">
                <Box display="flex" gap={3} alignItems="center">
                    <RangePicker
                        onChange={(values) => handleDatesChange(values)}
                        isLoading={isLoading}
                    />

                    {isTodayFilterApplied ? (
                        <>
                            <Typography variant="body2">
                                {t("common.compared_to")}
                            </Typography>

                            <Select
                                defaultValue="yesterday"
                                onChange={(e) =>
                                    handleTodayDatesChange(e.target.value)
                                }
                                input={<CustomSelectOutline />}
                                IconComponent={ExpandMoreIcon}
                                disabled={isLoading}
                            >
                                <MenuItem value="yesterday">
                                    {" "}
                                    {t("likeForLike.yesterday")}
                                </MenuItem>
                                <MenuItem value="same_day_prev_week">
                                    {t("likeForLike.same_day_last_week")}
                                </MenuItem>
                                <MenuItem value="same_day_prev_month">
                                    {t("likeForLike.same_day_prev_month")}
                                </MenuItem>
                                <MenuItem value="same_day_prev_3_month">
                                    {t("likeForLike.same_day_last_qur")}
                                </MenuItem>
                                <MenuItem value="same_day_prev_year">
                                    {t("likeForLike.same_day_last_year")}
                                </MenuItem>
                            </Select>
                        </>
                    ) : (
                        !isAllTimeFilterApplied && (
                            <Box display="flex" alignItems="center">
                                <Typography>
                                    {t("sales.compare_to_prev")}
                                </Typography>
                                <Switch
                                    checked={compareWithPrev}
                                    onChange={() =>
                                        setCompareWithPrev(!compareWithPrev)
                                    }
                                />
                            </Box>
                        )
                    )}
                </Box>

                <Box display="flex" alignItems="center" gap={4}>
                    {isGroupOwner && (
                        <ButtonSelect
                            value={selectedGroup}
                            onChange={handleGroupChange}
                            options={[allGroupsOption, ...allGroups]}
                            isLoading={isLoading}
                        />
                    )}

                    {/* <MultiSelectFilter
                        label={"Channel"}
                        items={[...allChannels]}
                        filterList={selectedChannels}
                        onChange={(value) => setSelectedChannels(value)}
                    /> */}

                    {/* <MultiSelectFilter
                        label={t("common.branch")}
                        items={[...allBranches]}
                        onChange={(value) => setSelectedChannels(value)}
                    /> */}

                    {/* <ExportButton
                        overviewPDF={{
                            ...overviewPDF,
                            isSingleChannelSelected,
                            isArabic,
                            printDate: `${new Date().toLocaleDateString()} - ${new Date().toLocaleTimeString()}`,
                        }}
                        pageName="aggregatorsPDF"
                        isLoading={isLoading}
                    /> */}
                </Box>
            </Box>

            <Grid
                container
                spacing={6}
                style={{
                    margin: isArabic ? 0 : "-24px",
                    marginTop: 2,
                    marginBottom: 2,
                }}
            >
                <GridCard
                    gridProps={{ xs: 12, md: 6 }}
                    padding={0}
                    height={382}
                    boxStyles={{ minHeight: "420px", height: "fit-content" }}
                >
                    <TopProducts data={topProducts} />
                </GridCard>

                <GridCard
                    gridProps={{ xs: 12, md: 6 }}
                    height={372}
                    boxStyles={{ minHeight: "420px", height: "fit-content" }}
                >
                    <Stack flexDirection="row" gap={20}>
                        <Stack>
                            <Typography
                                fontWeight="bold"
                                fontSize={20}
                                color="#202020"
                            >
                                {t("sales.orders_rejected")} (
                                {orderCanclledTotals?.canceled_percentage}%)
                            </Typography>
                            <Typography fontSize={16} color="#202020">
                                {orderCanclledTotals?.total_canceled_orders}{" "}
                                {t("sales.from_total_orders")}
                            </Typography>
                        </Stack>

                        <Stack>
                            <Typography
                                fontWeight="bold"
                                fontSize={20}
                                color="#202020"
                            >
                                {t("sales.lost_sales")}
                            </Typography>
                            <Typography
                                fontWeight="bold"
                                fontSize={16}
                                color="#202020"
                            >
                                {orderCanclledTotals?.total_lost_sales}{" "}
                                {t("common.currency")}
                            </Typography>
                        </Stack>
                    </Stack>
                    <OrdersChart data={totalLostData} />
                </GridCard>
            </Grid>

            <Grid container spacing={0} flexWrap="nowrap">
                <GridCard
                    fullWidth
                    padding={0}
                    boxStyles={{ minHeight: 500, height: "fit-content" }}
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        padding={6}
                        height={74}
                    >
                        <Typography
                            fontWeight="bold"
                            fontSize={20}
                            color="#202020"
                        >
                            {t("sales.trending_hours")} /{" "}
                            {t("sales.sales_channels")}
                        </Typography>
                    </Box>

                    <Divider />

                    <Stack spacing={5} width="100%">
                        <Box
                            padding={5}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            flexWrap="wrap"
                            gap={4}
                            rowGap={1}
                        >
                            {!isSingleChannelSelected && (
                                <GridCardFlatLabels
                                    data={channelsSales}
                                    isFlat
                                />
                            )}
                        </Box>

                        <Grid container padding={6}>
                            <Grid
                                item
                                xs={12}
                                sm={isSingleChannelSelected ? 12 : 8}
                            >
                                {!!trendingHours?.length && (
                                    <ComposedTrendingHours
                                        channelsSales={channelsSales}
                                        data={trendingHours}
                                    />
                                )}
                            </Grid>

                            {!isSingleChannelSelected && (
                                <Grid item xs={12} sm={4}>
                                    <Box display="flex" justifyContent="center">
                                        <Box
                                            width="350px"
                                            height="100%"
                                            pr={8}
                                            position="relative"
                                            // right={isArabic ? 0 : "-44px"}
                                            left={isArabic ? "-44px" : 0}
                                        >
                                            <DoughnutChart
                                                data={channelsSales}
                                                pieProps={{
                                                    innerRadius: 40,
                                                    outerRadius: 95,
                                                    cy: "48%",
                                                    cx: "55%",
                                                    paddingAngle: 0,
                                                    // label: data.length > 5 && false,
                                                }}
                                                legendProps={{
                                                    height: 0,
                                                    iconType: "circle",
                                                    layout: "vertical",
                                                    verticalAlign: "middle",
                                                    align: "left",
                                                    // wrapperStyle: { top: 0 },
                                                }}
                                                hideLegend
                                                hideLabels
                                                isInnerlabel
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </Stack>
                </GridCard>
            </Grid>
        </>
    );
};

export { Operations };
