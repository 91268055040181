import { Box, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getUserStorage } from "../../../../common/utils";
import { BranchDetails } from "./BranchDetails";
import { useEffect } from "react";

const LikeForLikeBranches = () => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const user = getUserStorage();
    const allBranches = user?.merchant?.branches?.map((branch) => ({
        id: branch.branch_id,
        label: branch.name,
    }));

    useEffect(() => {
        window.pendo.pageLoad({
            name: "Branches Like for Like Page",
        });
    }, []);
    return (
        <Stack spacing={8} minWidth="100%">
            <Grid
                container
                spacing={6}
                style={{
                    margin: isArabic ? 0 : "-24px",
                    marginTop: 0,
                    marginBottom: 0,
                }}
            >
                <BranchDetails
                    allBranches={allBranches}
                    label={`${t("likeForLike.first")} ${t(
                        "likeForLike.branch"
                    )}`}
                    gutter={isArabic}
                />

                <BranchDetails
                    allBranches={allBranches}
                    label={`${t("likeForLike.second")} ${t(
                        "likeForLike.branch"
                    )}`}
                />
            </Grid>
        </Stack>
    );
};

export { LikeForLikeBranches };
