import { Grid, Stack, Typography } from "@mui/material";
import { MyPaymentDetails } from "./MyPaymentDetails";
import { MyPlanInfo } from "./MyPlanInfo";
import { MyTransactions } from "./MyTransactions";
import { useEffect } from "react";

const Subscription = () => {
    useEffect(() => {
        window.pendo.pageLoad({
            name: "Subscription Page",
        });
    }, []);

    return (
        <Stack spacing={8} minWidth="100%">
            <Grid container rowGap={6}>
                {/* <MyPaymentDetails /> */}
                <MyPlanInfo />
                <MyTransactions />
            </Grid>
        </Stack>
    );
};

export { Subscription };
