export const sidebarItemStyles = {
    base: {
        paddingY: 0.5,
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        transition: "all 0.2s ease",
        borderRadius: "6px",
        paddingLeft: "30px", // Proper indentation
        paddingRight: "30px", // Proper indentation
    },
    active: {
        backgroundColor: "#08303A",
        color: "#FFFFFF",
        fontWeight: "bold",
    },
    hover: {
        backgroundColor: "#08303A",
        transform: "scale(1.02)",
    },
};
