import { Avatar, Box, Button, Stack, Typography } from "@mui/material";
import { OutlineButton } from "../../../../common/components";
import EditIcon from "@mui/icons-material/Edit";
import { AuthServices } from "../../../../Auth/services";
import { constants } from "../../../../config/constants";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { numberWithCommas } from "../../../../common/utils/numberWithCommas";
import { useMutation } from "@tanstack/react-query";

const SuccessTag = ({ label }) => (
    <Box
        height={26}
        backgroundColor="#effff4"
        border="1px solid"
        borderColor="#d9f3e1"
        borderRadius={4}
        display="flex"
        alignItems="center"
        px={2}
    >
        <Typography fontSize={14} color="#177F3D">
            {label}
        </Typography>
    </Box>
);

const ErrorTag = ({ label }) => (
    <Box
        height={26}
        backgroundColor="#fef2f2"
        border="1px solid"
        borderColor="#c74845"
        borderRadius={4}
        display="flex"
        alignItems="center"
        px={2}
    >
        <Typography fontSize={14} color="#B91D1B">
            {label}
        </Typography>
    </Box>
);

const SettingsBranchCard = ({ branch, gutter }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const { mutate: updateUserRole } = useMutation({
        mutationFn: AuthServices.updateRole,
        onSuccess: (res) => {
            if (!res.data.error) {
                window.open(`${window.location.origin}/overview`, "_self");
            }
        },
        onError: (err) => console.log(err),
    });

    const navigate = useNavigate();

    const handleUpdate = () => {
        sessionStorage.setItem("toBeEditedBranch", JSON.stringify(branch));

        navigate("/settings/branches/new");
    };

    const handleViewAsManager = (branch) => {
        const data = {
            manager_view: true,
            branch_id: branch.id,
        };

        updateUserRole(data);
    };

    return (
        <Box
            paddingRight={gutter ? (isArabic ? 0 : 6) : 0}
            paddingLeft={gutter ? (isArabic ? 6 : 0) : 0}
            paddingBottom={6}
        >
            <Stack
                border="1px solid #E5E7EB"
                width="100%"
                height={450}
                borderRadius={2}
            >
                {/* Card Header */}
                <Box
                    display="flex"
                    alignItems="center"
                    padding={5}
                    borderBottom="1px solid #E5E7EB"
                >
                    {/* Header Logo */}
                    <Box display="flex" alignItems="center" gap={3} flex={1}>
                        <Box>
                            <Avatar
                                alt="Branch logo"
                                // src="/branch_logo.png"
                                sx={{ width: 40, height: 40 }}
                            />
                        </Box>

                        <Stack spacing={0}>
                            <Typography fontSize={20} fontWeight="bold">
                                {branch?.name}
                            </Typography>
                            <Typography fontSize={16} color="#6B7280">
                                {branch?.name}
                            </Typography>
                        </Stack>
                    </Box>

                    <Box display="flex" gap={4} alignItems="center">
                        <OutlineButton
                            onClick={handleUpdate}
                            buttonStyles={{
                                height: 36,
                                minWidth: 80,
                                maxWidth: 100,
                                borderRadius: "6px",
                            }}
                            buttonProps={{
                                startIcon: !isArabic && (
                                    <EditIcon
                                        sx={{
                                            color: "#6C7381",
                                        }}
                                    />
                                ),
                                endIcon: isArabic && (
                                    <EditIcon
                                        sx={{
                                            color: "#6C7381",
                                        }}
                                    />
                                ),
                            }}
                        >
                            {t("common.edit")}
                        </OutlineButton>

                        {/* <OutlineButton
                            buttonStyles={{
                                height: 36,
                                width: 95,
                                borderRadius: "6px",
                            }}
                            buttonProps={{
                                startIcon: (
                                    <DeleteIcon
                                        sx={{
                                            color: "#6C7381",
                                        }}
                                    />
                                ),
                            }}
                        >
                            Delete
                        </OutlineButton> */}
                    </Box>
                    {/* Header Actions */}
                </Box>

                {/* Card Content */}
                <Stack px={5}>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        py={6}
                        borderBottom="1px solid #E5E7EB"
                    >
                        <Typography fontSize={16} color="#101827">
                            {t("settings.manager")}
                        </Typography>
                        <Typography fontSize={16} color="#6C7381">
                            {branch.manager || "-"}
                        </Typography>
                    </Box>

                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        py={6}
                        borderBottom="1px solid #E5E7EB"
                    >
                        <Typography fontSize={16} color="#101827">
                            {t("settings.sales_goal")}
                        </Typography>
                        <Typography fontSize={16} color="#6C7381">
                            {numberWithCommas(+branch.sales_goal) || "-"}{" "}
                            {branch?.sales_goal && t("common.currency")}
                        </Typography>
                    </Box>

                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        py={6}
                        borderBottom="1px solid #E5E7EB"
                    >
                        <Typography fontSize={16} color="#101827">
                            {t("settings.address")}
                        </Typography>
                        <Typography fontSize={16} color="#6C7381">
                            {branch.address}
                        </Typography>
                    </Box>

                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        py={6}
                    >
                        <Typography fontSize={16} color="#101827">
                            {t("settings.status")}
                        </Typography>

                        {/* {branch.isConnected ? (
                            <SuccessTag label="Connected to Foodics" />
                        ) : (
                            <ErrorTag label="Offline" />
                        )} */}

                        <SuccessTag label={t("settings.connected_foodics")} />
                    </Box>
                </Stack>

                {/* Card Actions */}

                <Box borderTop="1px solid #E5E7EB" height="100%">
                    <Button
                        fullWidth
                        sx={{
                            height: "100%",
                            textTransform: "capitalize",
                            fontSize: 16,
                            fontWeight: "bold",
                        }}
                        onClick={() => handleViewAsManager(branch)}
                    >
                        View As a Restaurant Manager
                    </Button>
                </Box>
            </Stack>
        </Box>
    );
};

export { SettingsBranchCard };
