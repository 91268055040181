import { Box, Grid, Stack, Typography } from "@mui/material";
import { GridCard, OutlineButton } from "../../../../common/components";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { services } from "../../../manager/services";
import { SettingsManagerCard } from "../Components/SettingsManagerCard";
import { useEffect, useMemo, useState } from "react";

const MyTeam = () => {
    const { t } = useTranslation();

    const [allManagers, setAllManagers] = useState([]);
    const navigate = useNavigate();

    const { data, isFetching } = useQuery({
        queryKey: ["allManagers"],
        queryFn: services.getManagers,
    });

    const allManagersResponse = useMemo(
        () =>
            data?.data?.response?.users.filter((user) => user.role !== 1) || [],
        [data]
    );

    useEffect(() => {
        setAllManagers(allManagersResponse);
    }, [allManagersResponse]);

    const handleDelete = (id) => {
        setAllManagers(allManagers.filter((m) => m.id !== id));
    };

    useEffect(() => {
        window.pendo.pageLoad({
            name: "My Team Page",
        });
    }, []);

    return (
        <Stack spacing={8} minWidth="100%">
            <Stack flexDirection="row" justifyContent="space-between">
                <Typography variant="h5" fontSize={24}></Typography>

                <OutlineButton
                    onClick={() => navigate("/settings/team/new")}
                    buttonStyles={{ width: 165 }}
                >
                    {t("settings.add_manager")}
                </OutlineButton>
            </Stack>

            <Grid container flexWrap="nowrap">
                <GridCard
                    fullWidth
                    boxStyles={{
                        minHeight: allManagers.length === 0 ? 500 : 1200,
                        height: "fit-content",
                    }}
                >
                    <Stack spacing={8}>
                        <Box>
                            <Typography variant="h5" fontSize={24}>
                                {t("settings.chain_managers")}
                            </Typography>
                        </Box>

                        <Grid
                            container
                            wrap="wrap"
                            width="100%"
                            justifyContent={
                                allManagers.length === 0 && "center"
                            }
                        >
                            {allManagers.length === 0 && !isFetching && (
                                <Typography alignSelf="center">
                                    {t("common.empty")}
                                </Typography>
                            )}
                            {allManagers?.map((branch, index) => {
                                return (
                                    <Grid xs={12} sm={6} md={4}>
                                        <SettingsManagerCard
                                            key={branch.id}
                                            manager={branch}
                                            gutter
                                            onUserDelete={(id) =>
                                                handleDelete(id)
                                            }
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Stack>
                </GridCard>
            </Grid>
        </Stack>
    );
};

export { MyTeam };
