import { Avatar, Box, Button, Grid, Stack, Typography } from "@mui/material";
import { GridCard, OutlineButton } from "../../../../common/components";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { constants } from "../../../../config/constants";
import { getUserStorage } from "../../../../common/utils";
import { SettingsBranchCard } from "../Components/SettingsBranchCard";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { AuthServices } from "../../../../Auth/services";

const MyBranches = () => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const user = getUserStorage();
    const isOwner = user.role === 1;
    const [ownerBranches, setOwnerBranches] = useState(
        user?.merchant?.branches?.map((branch) => ({
            id: branch.branch_id,
            name: branch.name,
            address: branch.address,
            phone: branch.address,
            manager: branch.manager,
            sales_goal: branch.sales_goal,
        }))
    );

    const navigate = useNavigate();

    const { data, isFetched } = useQuery({
        queryKey: ["me"],
        queryFn: AuthServices.getCurrentUser,
        retry: false,
    });

    useEffect(() => {
        if (isFetched) {
            const fetchedUser = data?.data?.response?.user;

            setOwnerBranches(
                fetchedUser?.merchant?.branches?.map((branch) => ({
                    id: branch.branch_id,
                    name: branch.name,
                    address: branch.address,
                    phone: branch.address,
                    manager: branch.manager,
                    sales_goal: branch.sales_goal,
                    opening_from: branch.opening_from,
                    opening_to: branch.opening_to,
                }))
            );
        }
    }, [isFetched, data]);

    useEffect(() => {
        window.pendo.pageLoad({
            name: "My Branches Page",
        });
    }, []);

    return (
        <Stack spacing={8} minWidth="100%">
            <Stack flexDirection="row" justifyContent="space-between">
                <Typography variant="h5" fontSize={24}></Typography>

                <OutlineButton
                    onClick={() => navigate("/settings/branches/add")}
                    buttonStyles={{ width: "fit-content" }}
                >
                    {t("settings.add_new_branch")}
                </OutlineButton>
            </Stack>

            <Grid container flexWrap="nowrap">
                <GridCard
                    fullWidth
                    boxStyles={{ minHeight: 1200, height: "fit-content" }}
                >
                    <Stack spacing={8}>
                        <Box>
                            <Typography variant="h5" fontSize={24}>
                                {t("settings.chain_branches")}
                            </Typography>
                        </Box>

                        <Grid container wrap="wrap">
                            {ownerBranches?.map((branch, index) => {
                                return (
                                    <Grid xs={12} md={6} key={branch.id}>
                                        <SettingsBranchCard
                                            key={branch.id}
                                            branch={branch}
                                            gutter={index % 2 === 0 && true}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Stack>
                </GridCard>
            </Grid>
        </Stack>
    );
};

export { MyBranches };
