import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { FrequentItems } from "../FrquentItems";
import { PageHeader } from "../../../../../common/components";
import { constants } from "../../../../../config/constants";
import i18next from "i18next";
import { ExtendArabicLogoPDF } from "../../../Overview/pdf/OverviewPDF";

const BasketFreqPDF = () => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const [printDate, setPrintDate] = useState("");
    const [period, setPeriod] = useState({
        startDate: null,
        endDate: format(new Date(), "yyyy-MM-dd"),
    });

    const [freqProducts, setFreqProducts] = useState([]);

    useEffect(() => {
        const pdfData = JSON.parse(localStorage.getItem("overview-pdf"));

        setFreqProducts(pdfData.freq_products);
        setPeriod(pdfData.period);
        setPrintDate(pdfData.printDate);

        if (pdfData.isArabic) {
            localStorage.setItem(constants.storage.language, "ar");
            i18next.changeLanguage("ar");
        }
    }, []);

    return (
        <Stack dir={isArabic ? "rtl" : "ltr"}>
            <Box
                height={55}
                backgroundColor="black"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                px={8}
                sx={{
                    pageBreakAfter: "avoid",
                }}
            >
                {isArabic ? (
                    <ExtendArabicLogoPDF />
                ) : (
                    <img
                        src="/logo_extend.svg"
                        width={176}
                        height="31px"
                        alt="lgo"
                    />
                )}

                <Typography
                    fontSize={12}
                    color="white"
                    fontWeight="bold"
                    position="relative"
                    top="12px"
                >
                    {printDate}
                </Typography>
            </Box>

            <Stack padding={8} spacing={8} minWidth="100%">
                <Stack spacing={4}>
                    <PageHeader title={t("basket.freq")} />

                    <Typography fontSize={14}>
                        {period.startDate} &rarr; {period.endDate}
                    </Typography>
                </Stack>

                <Box>
                    <Typography variant="h5">
                        {t("basket.products_freq")}
                    </Typography>
                </Box>

                {!freqProducts.length && (
                    <Box padding={40}>
                        <Typography variant="body1" align="center">
                            {t("common.empty")}
                        </Typography>
                    </Box>
                )}

                {freqProducts.map((prodcutsList, index) => (
                    <Box paddingTop={10}>
                        <FrequentItems
                            title={`${t("basket.top")} ${index + 1}`}
                            items={prodcutsList.recommends}
                            total={prodcutsList.total}
                        />
                    </Box>
                ))}
            </Stack>
        </Stack>
    );
};

export { BasketFreqPDF };
